body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #f8f8f8;
}
.top {
  border: none;
  border-radius: 0;
  height: 250px;
  background-size: 100%;
  background-image: url("./top.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y: 20px;
  text-align: center;
  color: #ffffff;
  padding-top: 20%;
}
.top h1 {
  font-size: 50px;
}
.top div {
  font-size: 20px;
}

@media (min-width: 576px) {
  .top {
    padding-top: 10%;
    height: 300px;
    background-position-y: 20px;
  }
}
@media (min-width: 768px) {
  .top {
    padding-top: 15%;
    height: 600px;
    background-position-y: -80px;
  }
}
.data-corona {
  margin-top: 15px;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid black;
}

.data-corona .card-body {
  border-radius: 0;
}

.gejala {
  border-radius: 12px;
  padding: 0;
  background: #5e54af;
  color: #ffffff;
  padding-top: 20px;
}

.gejala .content .card {
  border: none;
  border-radius: 12px;
  background: #5e54af;
  box-shadow: 6px 6px 12px #504795, -6px -6px 12px #6c61c9;
  height: 176px;
  color: #ffffff;
  margin-top: 10px;
}

.pengertian {
  border: none;
  border-radius: 0;
  border-right: 2px solid #5e54af;
}

.pencegahan {
  background-color: #343a40;
  color: #d8d6d6;
  border-radius: 12px;
}
.list-group-item:first-child {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
.list-group-item:last-child {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.list-group-item.active {
  background-color: #5e54af;
  border-color: #5e54af;
}

.list-group {
  margin-top: 15px;
  border-radius: 12px;
  background: #343a40;
  box-shadow: 6px 6px 12px #2c3136, -6px -6px 12px #3c434a;
}
.ket-pencegahan .card {
  margin-top: 15px;
  border: none;
  border-radius: 12px;
  background: #343a40;
  box-shadow: 6px 6px 12px #2c3136, -6px -6px 12px #3c434a;
}

.cari {
  border: none;
  border-radius: 0;
  border-bottom: 1.3px solid #000000;
}

input.cari:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;

  border-bottom: 1.5px solid #5e54af;
}

.footer {
  position: relative;
  bottom: 0;
  left: 0;
  margin-top: 100px;
  margin-bottom: 0;
  width: 100%;
  border-radius: 0;
  color: #d8d6d6;
  background-color: #343a40;
}
.table-provinsi {
  height: 400px;
  overflow-y: scroll;
}
